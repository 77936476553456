import { Link } from "gatsby"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const CookiePolicy = () => {
    return (
        <Layout>
            <Seo title="Cookie Policy" />
            <div className="container py-3">
                <h1>Cookie Policy</h1>
                <p>
                    We use cookies to help improve your experience of <Link to="/">www.wearedelta.com</Link>. This cookie policy is
                    part of Delta Corp's <Link to="/legal/privacy-policy/">privacy policy</Link>, and covers the use of cookies between
                    your device and our site. We also provide basic information on third-party services we may use, who may also use
                    cookies as part of their service, though they are not covered by our policy.
                </p>
                <p>
                    If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from{" "}
                    <Link to="/">www.wearedelta.com</Link>, with the understanding that we may be unable to provide you with some of
                    your desired content and services.
                </p>
                <h4>What is a cookie?</h4>
                <p>
                    A cookie is a small piece of data that a website stores on your device when you visit, typically containing
                    information about the website itself, a unique identifier that allows the site to recognise your web browser when
                    you return, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.
                </p>
                <p>
                    Cookies are used to enable certain features (eg. logging in), to track site usage (eg. analytics), to store your
                    user settings (eg. timezone, notification preferences), and to personalise your content (eg. advertising,
                    language).
                </p>
                <p>
                    Cookies set by the website you are visiting are normally referred to as “first-party cookies”, and typically only
                    track your activity on that particular site. Cookies set by other sites and companies (ie. third parties) are
                    called “third-party cookies”, and can be used to track you on other websites that use the same third-party service.
                    You can learn more about cookies on{" "}
                    <a href="https://cookiesandyou.com/" target="_blank" rel="noreferrer">
                        Cookies & You
                    </a>
                    .
                </p>
                <h4>Types of cookies and how we use them</h4>
                <div className="table-responsive">
                    <table className="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Cookie</th>
                                <th scope="col">Provider</th>
                                <th scope="col">Type</th>
                                <th scope="col">Expiry</th>
                                <th scope="col">Purpose</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">_ga</th>
                                <td>wearedelta.com.com</td>
                                <td>HTTP</td>
                                <td>2 years</td>
                                <td>
                                    Registers a unique ID that is used to generate statistical data on how the visitor uses the
                                    website.
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">_gat</th>
                                <td>wearedelta.com.com</td>
                                <td>HTTP</td>
                                <td>Session</td>
                                <td>Used by Google Analytics to throttle request rate.</td>
                            </tr>
                            <tr>
                                <th scope="row">_gid</th>
                                <td>wearedelta.com.com</td>
                                <td>HTTP</td>
                                <td>Session</td>
                                <td>
                                    Registers a unique ID that is used to generate statistical data on how the visitor uses the
                                    website.
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">cookieconsent</th>
                                <td>wearedelta.com.com</td>
                                <td>HTTP</td>
                                <td>1 year</td>
                                <td>Used to store the users cookie acceptance for this website.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>Essential cookies</h4>
                <p>
                    Essential cookies are crucial to your experience of a website, enabling core features like user logins, account
                    management, shopping carts and payment processing. We use essential cookies to enable certain functions on our
                    website.
                </p>
                <h4>Performance cookies</h4>
                <p>
                    Performance cookies are used in the tracking of how you use a website during your visit, without collecting
                    personal information about you. Typically, this information is anonymous and aggregated with information tracked
                    across all site users, to help companies understand visitor usage patterns, identify and diagnose problems or
                    errors their users may encounter, and make better strategic decisions in improving their audience’s overall website
                    experience. These cookies may be set by the website you’re visiting (first-party) or by third-party services. We
                    use performance cookies on our site.
                </p>
                <h4>Functionality cookies</h4>
                <p>
                    Functionality cookies are used in collecting information about your device and any settings you may configure on
                    the website you’re visiting (like language and timezone settings). With this information, websites can provide you
                    with customised, enhanced or optimised content and services. These cookies may be set by the website you’re
                    visiting (first-party) or by third-party service. We use functionality cookies for selected features on our site.
                </p>
                <h4>Targeting/advertising cookies</h4>
                <p>
                    Targeting/advertising cookies are used in determining what promotional content is more relevant and appropriate to
                    you and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you
                    see an advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content
                    presented to you. These cookies may be set by the website you’re visiting (first-party) or by third-party services.
                    Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same
                    third-party service. We use targeting/advertising cookies on our site.
                </p>
                <h4>Third-party cookies on our site</h4>
                <p>
                    We may employ third-party companies and individuals on our websites—for example, analytics providers and content
                    partners. We grant these third parties access to selected information to perform specific tasks on our behalf. They
                    may also set third-party cookies in order to deliver the services they are providing. Third-party cookies can be
                    used to track you on other websites that use the same third-party service. As we have no control over third-party
                    cookies, they are not covered by Delta Corp's cookie policy.
                </p>
                <h4>Our third-party privacy promise</h4>
                <p>
                    We review the privacy policies of all our third-party providers before enlisting their services to ensure their
                    practices align with ours. We will never knowingly include third-party services that compromise or violate the
                    privacy of our users.
                </p>
                <h4>How you can control or opt out of cookies</h4>
                <p>
                    If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our website.
                    Most browsers are configured to accept cookies by default, but you can update these settings to either refuse
                    cookies altogether, or to notify you when a website is trying to set or update a cookie.
                </p>
                <p>If you browse websites from multiple devices, you may need to update your settings on each individual device.</p>
                <p>
                    Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may
                    mean you are unable to access certain features and content across the sites you visit.
                </p>
                <p>This policy is effective as of 25 June 2018.</p>
            </div>
        </Layout>
    )
}

export default CookiePolicy
